import "core-js/modules/es.array.push.js";
//import VueSimpleVerify from "vue-simple-verify";
//import "../../node_modules/vue-simple-verify/dist/vue-simple-verify.css";
import Vcode from "vue-puzzle-vcode";
export default {
  components: {
    Vcode //VueSimpleVerify,

  },

  data() {
    return {
      win: 0,
      mobile: "",
      tokenString: "",
      sopenCheckcode: {},
      mobileDisabled: false,
      mobileRules: [v => !!v || "不能为空", v => v && v.length == 11 || "手机号码错误"],
      mobileCode: "",
      mobileCodeRules: [v => !!v || "不能为空", v => v && v.length == 4 || "验证码为4个字符"],
      passwdTest: [],
      sendIcon: "mdi-send",
      sendText: "发送验证码",
      verifyPass: false,
      verifyDisabled: false,
      verifyWidth: "",
      dialogCodeDisabled: false,
      postDisabled: true,
      otp: false,
      snackbar: {
        state: false,
        text: "text"
      },
      usinfo: {
        name: "",
        idcard: "",
        passwd: "",
        passwd2: ""
      },
      error: {
        name: "",
        idcard: "",
        passwd2: ""
      },
      show1: false,
      show2: false,
      rules: {
        required: value => !!value || "不允许为空.",
        min: v => v.length >= 6 || "密码最少需要6个字符"
      },
      usCookie: "",
      VcodeBox: {
        show: false,
        width: 310
      },
      weChatOpenid: "",
      options: {}
    };
  },

  mounted() {
    this.weChatOpenid = sessionStorage.getItem("weChatOpenid");
    let query = this.$route.query;
    this.win = query.w != undefined ? parseInt(query.w) : 0;
    this.sopenCheck();
    this.enToken();
  },

  methods: {
    onSuccess() {
      this.VcodeBox.show = false; // 通过验证后，需要手动关闭模态框

      this.sendCode();
    },

    onClose() {
      this.VcodeBox.show = false;
    },

    onResize() {
      let loginBox = document.getElementById("loginBox");
      this.verifyWidth = loginBox.clientWidth - 45;
    },

    sopenCheck() {
      this.sopenCheckcode = sessionStorage.getItem("sopenCheckcode");
      this.usCookie = sessionStorage.getItem("usCookie");
    },

    enToken() {
      let tokenString = sessionStorage.getItem("tokenString");

      if (tokenString != "undefined" && tokenString != null) {
        this.tokenString = tokenString;
      } else {
        let data = {
          module: "user",
          title: "enToken"
        };
        let that = this;
        this.$sopen.requestUscenterApi(data).then(function (res) {
          tokenString = res.data.user;
          sessionStorage.setItem("tokenString", tokenString);
          that.tokenString = tokenString;
        });
      }
    },

    newUser() {
      this.$router.push({
        path: "/reg?w=1"
      });
      this.win = 1;
    },

    keyInput() {
      if (this.mobile != null && this.mobile.length == 11) {
        this.VcodeBox.show = true;
      } else {
        this.VcodeBox.show = false;
      }
    },

    sendTime() {
      let timeOut = 30;
      let that = this;
      let d = new Date();
      let t = d.getTime();
      let sendTime = sessionStorage.getItem("sendTime");

      if (sendTime != "NaN") {
        sendTime = parseInt(sendTime);
      }

      if (isNaN(sendTime)) {
        this.dialogCodeDisabled = true;
        let v = t + timeOut * 1000;
        sessionStorage.setItem("sendTime", v);
        this.sendBtn = true;
        this.verifyPass = false;
        this.verifyDisabled = false;
        this.sendTime();
      } else if (parseInt(t) > sendTime) {
        //this.dialogCode = false;
        this.dialogCodeDisabled = false;
        this.sendText = "发送验证码";
        this.verifyDisabled = true;
        sessionStorage.removeItem("sendTime");
      } else {
        this.sendBtn = true;
        let no = Math.ceil((sendTime - t) / 1000);
        this.sendText = "接收中(" + no + ")...";
        setTimeout(function () {
          that.sendTime();
        }, 1000);
        return false;
      }
    },

    sendCode() {
      sessionStorage.setItem("mobile", this.mobile);
      let data = {
        module: "user",
        title: "checkus",
        data: {
          mobile: this.mobile,
          tokenString: this.tokenString
        }
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.user.errcode > 0) {
          that.snackbar = {
            state: true,
            text: res.data.user.errmsg
          };
        } else {
          that.dialogCodeDisabled = true;
          that.otp = true;
          that.sendTime();
        }
      });
    },

    dialogCodeClose() {
      sessionStorage.getItem("sendTime");
      this.dialogCode = false;
    },

    editMobile() {
      this.editWindow = true;
    },

    postMobile() {
      let mobile = sessionStorage.getItem("mobile");
      let data = {
        module: "user",
        title: "checkcode",
        data: {
          mobile: mobile,
          tokenString: this.tokenString,
          code: this.mobileCode,
          type: "user"
        }
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.user.errcode == 0) {
          let data = res.data.user.data;
          that.win = 2;
          sessionStorage.setItem("sopenCheckcode", JSON.stringify(data));
        } else {
          that.snackbar = {
            state: true,
            text: res.data.user.errmsg
          };
          that.mobileCode = "";
        }
      });
    },

    passwdCheck() {
      if (this.usinfo.passwd != this.usinfo.passwd2) {
        this.error.passwd2 = "确认密码与设定密码必须相同";
      } else {
        this.error.passwd2 = "";
      }
    },

    formNewUser() {
      for (let x in this.usinfo) {
        if (this.usinfo[x].length < 1) {
          this.snackbar = {
            state: true,
            text: "请填充完整信息"
          };
          return false;
        }
      }

      for (let x in this.error) {
        if (this.error[x].length > 0) {
          this.snackbar = {
            state: true,
            text: this.error[x]
          };
          return false;
        }
      }

      let sopenCheckcode = sessionStorage.getItem("sopenCheckcode");
      let data = {
        module: "user",
        title: "reg2",
        data: {
          tokenString: this.tokenString,
          checkcode: JSON.parse(sopenCheckcode),
          openid: this.weChatOpenid != undefined ? JSON.parse(this.weChatOpenid) : {},
          name: this.usinfo.name,
          idcard: this.usinfo.idcard,
          passwd: this.usinfo.passwd
        }
      };
      this.options = data;
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.user.errcode > 0) {
          that.snackbar = {
            state: true,
            text: res.data.user.errmsg
          };
        } else if (res.data.user.errcode == 0) {
          that.usCookie = res.data.user.cookie;
          that.$sopen.setCookie(res.data.user.cookie);
          that.win = 4;
        } else {
          that.snackbar = {
            state: true,
            text: "未知错误"
          };
        }
      });
    }

  },
  watch: {
    $route(newval) {
      this.win = newval.query.w != undefined ? parseInt(newval.query.w) : 0;
    },

    sopenCheckcode(val) {
      if (val && Object.keys(val).length > 0) {
        this.win = 2;
      }
    },

    win(x) {
      if (x == 1) this.onResize();
    },

    usCookie(val) {
      if (val && val.length > 0) {
        this.win = 3;
        sessionStorage.removeItem("tokenString");
        sessionStorage.removeItem("sopenCheckcode");
      }
    }

  }
};