import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.array.push.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c(VApp, [_c(VContainer, {
    attrs: {
      "app": "",
      "fluid": ""
    }
  }, [_c('Vcode', {
    attrs: {
      "show": _vm.VcodeBox.show,
      "canvasWidth": _vm.VcodeBox.width
    },
    on: {
      "success": _vm.onSuccess,
      "close": _vm.onClose
    }
  }), _c('div', {
    staticClass: "mb-0 d-flex justify-center"
  }, [_c(VCard, {
    staticClass: "rounded-b-0",
    attrs: {
      "id": "loginBox",
      "max-width": "420",
      "dark": "",
      "width": "100%",
      "height": "65"
    }
  }, [_c(VImg, {
    attrs: {
      "height": "65px",
      "src": require("./images/bg-003.png")
    }
  }, [_c(VCardTitle, {
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" 用户注册 ")])], 1)], 1)], 1), _vm.win < 1 ? _c('div', {
    staticClass: "mb-2 d-flex justify-center"
  }, [_c(VCard, {
    staticClass: "mb-2",
    attrs: {
      "max-width": "420",
      "width": "100%",
      "tile": "",
      "outlined": ""
    }
  }, [_c(VCardTitle, {
    staticClass: "subtitle-1"
  }, [_vm._v(" 注册须知 ")]), _c(VCardText, {
    staticStyle: {
      "font-size": "18px",
      "line-height": "150%"
    }
  }, [_vm._v(" 1. 使用实名注册，需要录入身份证号码；"), _c('br'), _vm._v(" 2. 教师、家长、学生均可以注册；"), _c('br'), _vm._v(" 3. 家长注册允许关联N个学生，无需重复注册；"), _c('br'), _vm._v(" 4. 同时是教师、家长身份的，无需重复注册。"), _c('br')]), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "teal darken-3",
      "dark": "",
      "block": ""
    },
    on: {
      "click": _vm.newUser
    }
  }, [_vm._v("我知道了")])], 1)], 1)], 1) : _vm._e(), _vm.win == 1 ? _c('div', {
    staticClass: "mb-2 d-flex justify-center"
  }, [_c(VCard, {
    attrs: {
      "tile": "",
      "outlined": "",
      "max-width": "420",
      "width": "100%"
    }
  }, [_c(VCardTitle, [_vm._v(" 注册手机号码 ")]), _c(VForm, {
    ref: "form"
  }, [_c(VCardText, {
    staticClass: "px-5 pt-5"
  }, [_c(VTextField, {
    staticStyle: {
      "font-size": "25px"
    },
    attrs: {
      "prepend-inner-icon": "mdi-cellphone",
      "counter": 11,
      "rules": _vm.mobileRules,
      "label": "手机号码",
      "required": "",
      "disabled": _vm.dialogCodeDisabled,
      "outlined": ""
    },
    on: {
      "input": _vm.keyInput
    },
    model: {
      value: _vm.mobile,
      callback: function ($$v) {
        _vm.mobile = $$v;
      },
      expression: "mobile"
    }
  })], 1), _vm.otp ? _c(VCardText, {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.sendText) + " "), _c(VOtpInput, {
    staticStyle: {
      "width": "210px",
      "margin": "0px auto"
    },
    attrs: {
      "length": "4"
    },
    on: {
      "finish": _vm.postMobile
    },
    model: {
      value: _vm.mobileCode,
      callback: function ($$v) {
        _vm.mobileCode = $$v;
      },
      expression: "mobileCode"
    }
  })], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.win == 2 ? _c('div', {
    staticClass: "mb-2 d-flex justify-center"
  }, [_c('validation-observer', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        invalid
      }) {
        return [_c(VCard, {
          attrs: {
            "tile": "",
            "outlined": "",
            "max-width": "420",
            "width": "100%"
          }
        }, [_c(VCardTitle, {
          staticClass: "subtitle-1",
          attrs: {
            "primary-title": ""
          }
        }, [_c(VIcon, {
          staticClass: "mr-2"
        }, [_vm._v("mdi-card-account-details-star-outline")]), _vm._v(" 注册信息设定 ")], 1), _c(VDivider), _c(VCardText, {
          staticClass: "subtitle-1"
        }, [_c('p', [_vm._v("注意：")]), _c('p', [_vm._v(" 1.家长请使用本人身份证注册，注册成功后允许添加多名子女，扮演学生参于学校及班级教学活动； ")]), _c('p', [_vm._v(" 2.用户具有教师、家长双重身份时，以教师身份注册后，允许作为家长添加多名子女。 ")]), _c('p', [_vm._v("3.学生年龄太小的，家长可以帮助孩子进行注册。")])]), _c(VCardText, {
          staticClass: "px-7"
        }, [_c('validation-provider', {
          attrs: {
            "name": "登录ID",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              errors
            }) {
              return [_c(VTextField, {
                attrs: {
                  "error-messages": errors,
                  "label": "我的真实姓名",
                  "prepend-inner-icon": "mdi-account",
                  "hint": "家长身份注册的，请使用自己的真实姓名",
                  "outlined": ""
                },
                model: {
                  value: _vm.usinfo.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.usinfo, "name", $$v);
                  },
                  expression: "usinfo.name"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": "我的身份证号码",
            "rules": "required|min:8"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              errors
            }) {
              return [_c(VTextField, {
                attrs: {
                  "error-messages": errors,
                  "label": "我的身份证号码",
                  "prepend-inner-icon": "mdi-card-account-details-outline",
                  "hint": "家长身份注册的，请使用自己的身份证号码",
                  "counter": "",
                  "outlined": ""
                },
                model: {
                  value: _vm.usinfo.idcard,
                  callback: function ($$v) {
                    _vm.$set(_vm.usinfo, "idcard", $$v);
                  },
                  expression: "usinfo.idcard"
                }
              })];
            }
          }], null, true)
        })], 1), _c(VCardText, {
          staticClass: "px-7"
        }, [_c('validation-provider', {
          attrs: {
            "name": "设定密码",
            "rules": "required|min:6|max:18|password|confirmed:usinfo.passwd2"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              errors
            }) {
              return [_c(VTextField, {
                attrs: {
                  "label": "设定密码",
                  "prepend-inner-icon": "mdi-lock",
                  "append-icon": _vm.show1 ? 'mdi-eye' : 'mdi-eye-off',
                  "error-messages": errors,
                  "type": _vm.show1 ? 'text' : 'password',
                  "hint": "密码最少6个字符",
                  "counter": "",
                  "outlined": ""
                },
                on: {
                  "click:append": function ($event) {
                    _vm.show1 = !_vm.show1;
                  }
                },
                model: {
                  value: _vm.usinfo.passwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.usinfo, "passwd", $$v);
                  },
                  expression: "usinfo.passwd"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": "确认密码",
            "rules": "required|min:6|max:18|password",
            "vid": "usinfo.passwd2"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              errors
            }) {
              return [_c(VTextField, {
                attrs: {
                  "label": "确认密码",
                  "type": _vm.show2 ? 'text' : 'password',
                  "append-icon": _vm.show2 ? 'mdi-eye' : 'mdi-eye-off',
                  "prepend-inner-icon": "mdi-lock-outline",
                  "counter": "",
                  "error-messages": errors,
                  "outlined": ""
                },
                on: {
                  "click:append": function ($event) {
                    _vm.show2 = !_vm.show2;
                  },
                  "input": _vm.passwdCheck
                },
                model: {
                  value: _vm.usinfo.passwd2,
                  callback: function ($$v) {
                    _vm.$set(_vm.usinfo, "passwd2", $$v);
                  },
                  expression: "usinfo.passwd2"
                }
              })];
            }
          }], null, true)
        })], 1), _c(VDivider), _c(VCardActions, {
          staticClass: "pa-4"
        }, [_c(VSpacer), _c(VBtn, {
          attrs: {
            "color": "teal darken-3",
            "disabled": invalid,
            "dark": ""
          },
          on: {
            "click": _vm.formNewUser
          }
        }, [_c(VIcon, {
          staticClass: "mr-2"
        }, [_vm._v("mdi-check")]), _vm._v(" 确定提交 ")], 1)], 1)], 1)];
      }
    }], null, false, 1440842868)
  })], 1) : _vm._e(), _vm.win == 3 ? _c('div', {
    staticClass: "mb-2 d-flex justify-center"
  }, [_c(VCard, {
    attrs: {
      "tile": "",
      "outlined": "",
      "max-width": "420",
      "width": "100%"
    }
  }, [_c(VCardTitle, [_vm._v(" 注册完成 ")]), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "teal darken-3",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          path: '/user'
        });
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-shield-account-outline")]), _vm._v(" 登录社区 ")], 1)], 1)], 1)], 1) : _vm._e()], 1), _c(VSnackbar, {
    attrs: {
      "centered": "",
      "color": "grey darken-3"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function ({
        attrs
      }) {
        return [_c(VBtn, _vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              _vm.snackbar.state = false;
            }
          }
        }, 'v-btn', attrs, false), [_c(VIcon, [_vm._v("mdi-close")])], 1)];
      }
    }]),
    model: {
      value: _vm.snackbar.state,
      callback: function ($$v) {
        _vm.$set(_vm.snackbar, "state", $$v);
      },
      expression: "snackbar.state"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbar.text) + " ")])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };